@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* @import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville&display=swap'); */

body {
  /* font-family: 'Libre Baskerville', serif; */
  font-family: 'Open Sans', sans-serif;
  height:100%;
  letter-spacing: 2px;
  overflow-x: hidden;
  margin:0;
  padding:0;
  background-color: #FAFAFA;
}

