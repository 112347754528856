/* @import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap');

body {
  /* font-family: 'Libre Baskerville', serif; */
  font-family: 'Open Sans', sans-serif;
  height:100%;
  letter-spacing: 2px;
  overflow-x: hidden;
  margin:0;
  padding:0;
  background-color: #FAFAFA;
}
